@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@700&display=swap");
body {
	margin: 0;
        background-color: black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #fffceb

}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.link:focus {
	outline: none;
}


.imageMobile {
	display: none;
}
@media only screen and (max-width: 480px) {
	.imageDesktop {
		display: none;
	}
	.imageMobile {
		display: block;
	}
}
@media only screen and (min-device-width: 1100px) {
	.logoContainer {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
} 